import {FC} from 'react'
import {OrderItemModel} from '../models/OrderItemModel'
import {ItemStatus, PaymentStatus} from '../constants'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../../_metronic/helpers'
import Tooltip from 'react-tooltip-lite'
import {useIntl} from 'react-intl'
type Props = {
  item: OrderItemModel
}
const ItemActionBox: FC<Props> = (props) => {
  const id = props.item.id
  const intl = useIntl()
  const handleEditButtonClick = (e: any) => {
    if (props.item.paymentStatus != PaymentStatus.PAID) {
      e.preventDefault() // Prevent the Link from navigating
      e.stopPropagation() // Stop the event from propagating further
    }
  }
  const editable =
    props.item.paymentStatus == PaymentStatus.PAID && props.item.status == ItemStatus.DONE
  return (
    <div>
      <Tooltip
        content={intl.formatMessage({
          id: !editable
            ? props.item.paymentStatus == PaymentStatus.PAID
              ? 'ITEM.ACTIONS.EDIT.PENDING'
              : 'ITEM.ACTIONS.EDIT.DISABLE'
            : 'ITEM.ACTIONS.EDIT',
        })}
        className='tooltips'
      >
        <Link
          to={'/edit/' + id.toString()}
          onClick={handleEditButtonClick}
          className={`btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 ${
            !editable ? 'disabled' : ''
          }`}
          title='Edit Item'
        >
          <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
        </Link>
      </Tooltip>
    </div>
  )
}
export default ItemActionBox
