import {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {LoginSocialGoogle, IResolveParams} from 'reactjs-social-login'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {useIntl} from 'react-intl'
import {register, socialRegister} from '../redux/AuthRedux'
import {UserRegisterModel} from '../models/UserRegisterModel'
import {COUNTRY_CODES, USER_TYPE} from '../../profile/constants'
import {
  APP_TERM_AND_CONDITION_LINK,
  Language,
  PHONE_REGEX,
  POSTCODE_REGEX,
} from '../../../shared/constants'

const initialValues: UserRegisterModel = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
  changePassword: false,
  country: COUNTRY_CODES.JP,
  acceptTerms: false,
  userType: USER_TYPE.INDIVIDUAL,
  companyName: '',
  companyAddress: '',
  postCode: '',
  phone: '',
  isBankTransfer: false,
}

export function Registration() {
  const dispatch = useDispatch()
  const intl = useIntl()
  //get current language

  const currentLocale = intl.locale
  const registrationSchema = Yup.object().shape({
    userType: Yup.string().required(
      intl.formatMessage({id: 'Registration.form.USER_TYPE.REQUIRED'})
    ),
    companyName: Yup.string().when('userType', {
      is: USER_TYPE.ENTERPRISE,
      then: Yup.string()
        .required(intl.formatMessage({id: 'Registration.form.COMPANY_NAME.REQUIRED'}))
        .min(1, intl.formatMessage({id: 'Registration.form.COMPANY_NAME.MIN'}))
        .max(50, intl.formatMessage({id: 'Registration.form.COMPANY_NAME.MAX'})),
      otherwise: Yup.string().notRequired(),
    }),
    companyAddress: Yup.string().when('userType', {
      is: USER_TYPE.ENTERPRISE,
      then: Yup.string()
        .required(intl.formatMessage({id: 'Registration.form.COMPANY_ADDRESS.REQUIRED'}))
        .min(1, intl.formatMessage({id: 'Registration.form.COMPANY_ADDRESS.MIN'}))
        .max(250, intl.formatMessage({id: 'Registration.form.COMPANY_ADDRESS.MAX'})),
      otherwise: Yup.string().notRequired(),
    }),
    postCode: Yup.string().when('userType', {
      is: USER_TYPE.ENTERPRISE,
      then: Yup.string()
        .required(intl.formatMessage({id: 'Registration.form.POST_CODE.REQUIRED'}))
        .matches(POSTCODE_REGEX, intl.formatMessage({id: 'Registration.form.POST_CODE.FORMAT'})),
      otherwise: Yup.string().notRequired(),
    }),
    phone: Yup.string().when('userType', {
      is: USER_TYPE.ENTERPRISE,
      then: Yup.string()
        .required(intl.formatMessage({id: 'Registration.form.PHONE.REQUIRED'}))
        .matches(PHONE_REGEX, intl.formatMessage({id: 'Registration.form.PHONE.FORMAT'})),
      otherwise: Yup.string().notRequired(),
    }),
    country: Yup.string().required(intl.formatMessage({id: 'Registration.form.COUNTRY.REQUIRED'})),
    firstName: Yup.string()
      .min(1, intl.formatMessage({id: 'Registration.form.FIRST_NAME.MIN'}))
      .max(50, intl.formatMessage({id: 'Registration.form.FIRST_NAME.MAX'}))
      .required(intl.formatMessage({id: 'Registration.form.FIRST_NAME.REQUIRED'})),
    email: Yup.string()
      .email(intl.formatMessage({id: 'Registration.form.EMAIL.FORMAT'}))
      .min(1, intl.formatMessage({id: 'Registration.form.EMAIL.MIN'}))
      .max(50, intl.formatMessage({id: 'Registration.form.EMAIL.MAX'}))
      .required(intl.formatMessage({id: 'Registration.form.EMAIL.REQUIRED'})),
    lastName: Yup.string()
      .min(1, intl.formatMessage({id: 'Registration.form.LAST_NAME.MIN'}))
      .max(50, intl.formatMessage({id: 'Registration.form.LAST_NAME.MAX'}))
      .required(intl.formatMessage({id: 'Registration.form.LAST_NAME.REQUIRED'})),
    password: Yup.string()
      .min(8, intl.formatMessage({id: 'Registration.form.PASS.MIN'}))
      .max(50, intl.formatMessage({id: 'Registration.form.PASS.MAX'}))
      .required(intl.formatMessage({id: 'Registration.form.PASS.REQUIRED'})),
    confirmPassword: Yup.string()
      .required(intl.formatMessage({id: 'Registration.form.CONFIRM_PASS.REQUIRED'}))
      .when('password', {
        is: (val: string) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref('password')],
          intl.formatMessage({id: 'Registration.form.PASS.NOT_MATCH'})
        ),
      }),
    acceptTerms: Yup.bool().required(intl.formatMessage({id: 'Registration.form.TERM.REQUIRED'})),
  })
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setErrors, setSubmitting}) => {
      setSubmitting(true)
      await dispatch(register({user: values}))
    },
  })

  const handleSocialLogin = ({provider, data}: IResolveParams) => {
    const accessToken = data?.access_token || data?.accessToken
    dispatch(
      socialRegister({
        registerRequest: {
          accessToken: accessToken,
          provider: provider,
          country: formik.values.country,
        },
      })
    )
  }

  const handleError = (error: any) => {
    console.log(error)
  }
  
  const autoFillTheCountryWithTheCurrentLocale = () => {
    const browserLang = navigator.language || navigator.languages[0]
    if (browserLang?.startsWith('ja')) {
      formik.setFieldValue('country', COUNTRY_CODES.JP)
    } else {
      formik.setFieldValue('country', COUNTRY_CODES.US)
    }
  }
  useEffect(() => {
    PasswordMeterComponent.bootstrap()
    autoFillTheCountryWithTheCurrentLocale()
  }, [])

  return (
    <form
      className='w-lg-500px form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='mb-10 text-center'>
        {/* begin::Title */}
        <h1 className='text-dark mb-3'>{intl.formatMessage({id: 'Registration.header.TITLE'})}</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-400 fw-bold fs-4'>
          <Link to='/auth/login' className='link-primary'>
            {intl.formatMessage({id: 'Registration.header.SUB_TITLE'})}
          </Link>
          <Link
            to='/auth/forgot-password'
            className='link-primary fw-bolder'
            style={{marginLeft: '5px'}}
          >
            {intl.formatMessage({id: 'Registration.header.FORGOT'})}
          </Link>
        </div>
        {/* end::Link */}
      </div>
      {/* begin::Google link */}
      <LoginSocialGoogle
        client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID ?? ''}
        scope={process.env.REACT_APP_GOOGLE_OAUTH2_SCOPES ?? ''}
        onResolve={handleSocialLogin}
        onReject={handleError}
        isOnlyGetToken
      >
        <button type='button' className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
            className='h-20px me-3'
          />
          {intl.formatMessage({id: 'Login.form.CONTINUE_GG'})}
        </button>
      </LoginSocialGoogle>
      <div className='d-flex align-items-center mb-7'>
        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
        <span
          className='fw-bold text-gray-400 fs-7 mx-2'
          style={{
            minWidth: '45px',
            textAlign: 'center',
          }}
        >
          {intl.formatMessage({id: 'Login.body.OR'})}
        </span>
        <div className='border-bottom border-gray-300 mw-50 w-100'></div>
      </div>
      <div className='row fv-row mb-7 '>
        <div className={'col-xl-6 ' + (currentLocale === Language.JAPANESES ? 'order-2' : '')}>
          <label className='form-label fw-bolder text-dark fs-6'>
            {intl.formatMessage({id: 'Registration.form.FIRST_NAME'})}
          </label>
          <input
            placeholder={intl.formatMessage({id: 'Registration.form.FIRST_NAME.HOLDER'})}
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('firstName')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.firstName && formik.errors.firstName,
              },
              {
                'is-valid': formik.touched.firstName && !formik.errors.firstName,
              }
            )}
          />
          {formik.touched.firstName && formik.errors.firstName && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.firstName}</span>
              </div>
            </div>
          )}
        </div>
        <div className={'col-xl-6 ' + (currentLocale === Language.JAPANESES ? 'order-1' : '')}>
          {/* begin::Form group Lastname */}

          <label className='form-label fw-bolder text-dark fs-6'>
            {intl.formatMessage({id: 'Registration.form.LAST_NAME'})}
          </label>
          <input
            placeholder={intl.formatMessage({id: 'Registration.form.LAST_NAME.HOLDER'})}
            type='text'
            autoComplete='off'
            {...formik.getFieldProps('lastName')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {
                'is-invalid': formik.touched.lastName && formik.errors.lastName,
              },
              {
                'is-valid': formik.touched.lastName && !formik.errors.lastName,
              }
            )}
          />
          {formik.touched.lastName && formik.errors.lastName && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.lastName}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}
      </div>
      <div className='fv-row mb-7'>
        <label className='form-label fw-bolder text-dark fs-6'>
          {intl.formatMessage({id: 'Registration.form.EMAIL'})}
        </label>
        <input
          placeholder={intl.formatMessage({id: 'Registration.form.EMAIL.HOLDER'})}
          type='email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      <div className='fv-row mb-7'>
        <label className='form-label fw-bolder text-dark fs-6'>
          {intl.formatMessage({id: 'Registration.form.COUNTRY'})}
        </label>
        <select
          {...formik.getFieldProps('country')}
          className={clsx(
            'form-select form-select-solid form-select-lg',
            {'is-invalid': formik.touched.country && formik.errors.country},
            {
              'is-valid': formik.touched.country && !formik.errors.country,
            }
          )}
        >
          <option value=''> {intl.formatMessage({id: 'Registration.form.COUNTRY.SELECT'})} </option>
          <option value={COUNTRY_CODES.JP}>
            {intl.formatMessage({id: 'Registration.form.COUNTRY.JA'})}
          </option>
          <option value={COUNTRY_CODES.US}>
            {intl.formatMessage({id: 'Registration.form.COUNTRY.EN'})}
          </option>
        </select>
        {formik.touched.country && formik.errors.country && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.country}</span>
            </div>
          </div>
        )}
      </div>
      <div className='mb-7 fv-row' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>
            {intl.formatMessage({id: 'Registration.form.PASS'})}
          </label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder={intl.formatMessage({id: 'Registration.form.PASS.HOLDER'})}
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          {/* begin::Meter */}
          <div
            className='d-flex align-items-center mb-3'
            data-kt-password-meter-control='highlight'
          >
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
          </div>
          {/* end::Meter */}
        </div>
        <div className='text-muted'>
          {intl.formatMessage({id: 'Registration.form.PASS.FORMAT'})}
        </div>
      </div>
      <div className='fv-row mb-7'>
        <label className='form-label fw-bolder text-dark fs-6'>
          {intl.formatMessage({id: 'Registration.form.CONFIRM_PASS'})}
        </label>
        <input
          type='password'
          placeholder={intl.formatMessage({id: 'Registration.form.CONFIRM_PASS.HOLDER'})}
          autoComplete='off'
          {...formik.getFieldProps('confirmPassword')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.confirmPassword && formik.errors.confirmPassword,
            },
            {
              'is-valid': formik.touched.confirmPassword && !formik.errors.confirmPassword,
            }
          )}
        />
        {formik.touched.confirmPassword && formik.errors.confirmPassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.confirmPassword}</span>
            </div>
          </div>
        )}
      </div>
      <div className='fv-row mb-7'>
        <label className='form-label fw-bolder text-dark fs-6'>
          {intl.formatMessage({id: 'Registration.form.USER_TYPE'})}
        </label>
        <div className='d-flex'>
          <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
            <input
              className='form-check-input'
              type='radio'
              defaultChecked={true}
              {...formik.getFieldProps('userType')}
              value={USER_TYPE.INDIVIDUAL}
            />
            <span className='form-check-label'>
              {intl.formatMessage({id: 'Registration.form.INDIVIDUAL'})}
            </span>
          </label>

          <label className='form-check form-check-sm form-check-custom form-check-solid'>
            <input
              className='form-check-input'
              type='radio'
              {...formik.getFieldProps('userType')}
              value={USER_TYPE.ENTERPRISE}
            />
            <span className='form-check-label'>
              {intl.formatMessage({id: 'Registration.form.ENTERPRISE'})}
            </span>
          </label>
        </div>
      </div>
      {/* -------------------- ENTERPRISE FIELDS --------------------------- */}
      {formik.values.userType === USER_TYPE.ENTERPRISE && (
        <div className='row fv-row mb-7'>
          <div className='col-xl-6'>
            <label className='form-label fw-bolder text-dark fs-6'>
              {intl.formatMessage({id: 'Registration.form.COMPANY_NAME'})}
            </label>
            <input
              placeholder={intl.formatMessage({id: 'Registration.form.COMPANY_NAME.HOLDER'})}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('companyName')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.companyName && formik.errors.companyName,
                },
                {
                  'is-valid': formik.touched.companyName && !formik.errors.companyName,
                }
              )}
            />
            {formik.touched.companyName && formik.errors.companyName && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.companyName}</span>
                </div>
              </div>
            )}
          </div>
          <div className='col-xl-6'>
            <label className='form-label fw-bolder text-dark fs-6'>
              {intl.formatMessage({id: 'Registration.form.PHONE'})}
            </label>
            <input
              placeholder={intl.formatMessage({id: 'Registration.form.PHONE.HOLDER'})}
              type='tel'
              autoComplete='off'
              {...formik.getFieldProps('phone')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {'is-invalid': formik.touched.phone && formik.errors.phone},
                {
                  'is-valid': formik.touched.phone && !formik.errors.phone,
                }
              )}
            />
            {formik.touched.phone && formik.errors.phone && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.phone}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
        </div>
      )}
      {formik.values.userType === USER_TYPE.ENTERPRISE && (
        <div className='row fv-row mb-7'>
          <div className='col-xl-6'>
            <label className='form-label fw-bolder text-dark fs-6'>
              {intl.formatMessage({id: 'Registration.form.POST_CODE'})}
            </label>
            <input
              placeholder={intl.formatMessage({id: 'Registration.form.POST_CODE.HOLDER'})}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('postCode')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.postCode && formik.errors.postCode,
                },
                {
                  'is-valid': formik.touched.postCode && !formik.errors.postCode,
                }
              )}
            />
            {formik.touched.postCode && formik.errors.postCode && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.postCode}</span>
                </div>
              </div>
            )}
          </div>
          <div className='col-xl-6'>
            <label className='form-label fw-bolder text-dark fs-6'>
              {intl.formatMessage({id: 'Registration.form.COMPANY_ADDRESS'})}
            </label>
            <input
              placeholder={intl.formatMessage({id: 'Registration.form.COMPANY_ADDRESS.HOLDER'})}
              type='text'
              autoComplete='off'
              {...formik.getFieldProps('companyAddress')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                {
                  'is-invalid': formik.touched.companyAddress && formik.errors.companyAddress,
                },
                {
                  'is-valid': formik.touched.companyAddress && !formik.errors.companyAddress,
                }
              )}
            />
            {formik.touched.companyAddress && formik.errors.companyAddress && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.companyAddress}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {formik.values.userType === USER_TYPE.ENTERPRISE &&
        formik.values.country === COUNTRY_CODES.JP && (
          <div className='fv-row mb-7'>
            <label className='form-label fw-bolder text-dark fs-6'>
              {intl.formatMessage({id: 'Download.payment'})}
            </label>
            <div className='d-flex'>
              <label className='form-check form-check-sm form-check-custom form-check-solid me-5'>
                <input className='form-check-input' type='checkbox' checked={true} />
                <span className='form-check-label'>
                  {intl.formatMessage({id: 'payment.VISA.title'})}
                </span>
              </label>

              <label className='form-check form-check-sm form-check-custom form-check-solid'>
                <input
                  className='form-check-input'
                  type='checkbox'
                  {...formik.getFieldProps('isBankTransfer')}
                />
                <span className='form-check-label'>
                  {intl.formatMessage({id: 'payment.BANK.title'})}
                </span>
              </label>
            </div>
          </div>
        )}
      {/* -------------------- ENTERPRISE FIELDS --------------------------- */}
      <div className='fv-row mb-10'>
        <div className='form-check form-check-custom form-check-solid'>
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
            {...formik.getFieldProps('acceptTerms')}
          />
          <label
            className='form-check-label fw-bold text-gray-700 fs-6'
            htmlFor='kt_login_toc_agree'
          >
            {intl.locale === 'ja' ? (
              <a
                target='_blank'
                href={APP_TERM_AND_CONDITION_LINK}
                className='ms-1 link-primary'
                rel='noreferrer'
              >
                {intl.formatMessage({id: 'Registration.form.TERM.LINK'})}
              </a>
            ) : (
              intl.formatMessage({id: 'Registration.form.TERM'})
            )}
            {intl.locale === 'ja' ? (
              intl.formatMessage({id: 'Registration.form.TERM'})
            ) : (
              <a
                target='_blank'
                href={APP_TERM_AND_CONDITION_LINK}
                className='ms-1 link-primary'
                rel='noreferrer'
              >
                {intl.formatMessage({id: 'Registration.form.TERM.LINK'})}.
              </a>
            )}
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.acceptTerms}</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={!formik.isValid || !formik.values.acceptTerms}
        >
          {!formik.isSubmitting ? (
            <span className='indicator-label'>
              {intl.formatMessage({id: 'Registration.form.SUBMIT'})}
            </span>
          ) : (
            <span className='indicator-progress' style={{display: 'block'}}>
              {intl.formatMessage({id: 'Login.form.WAITING'})}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
          >
            {intl.formatMessage({id: 'Registration.form.CANCEL'})}
          </button>
        </Link>
      </div>
    </form>
  )
}
